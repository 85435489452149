// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-anti-wrinkle-injections-btx-tsx": () => import("./../../../src/pages/anti-wrinkle-injections-btx.tsx" /* webpackChunkName: "component---src-pages-anti-wrinkle-injections-btx-tsx" */),
  "component---src-pages-cheek-fillers-tsx": () => import("./../../../src/pages/cheek-fillers.tsx" /* webpackChunkName: "component---src-pages-cheek-fillers-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-dermal-fillers-tsx": () => import("./../../../src/pages/dermal-fillers.tsx" /* webpackChunkName: "component---src-pages-dermal-fillers-tsx" */),
  "component---src-pages-dermaplaning-tsx": () => import("./../../../src/pages/dermaplaning.tsx" /* webpackChunkName: "component---src-pages-dermaplaning-tsx" */),
  "component---src-pages-eyebrows-lamination-and-tint-tsx": () => import("./../../../src/pages/eyebrows-lamination-and-tint.tsx" /* webpackChunkName: "component---src-pages-eyebrows-lamination-and-tint-tsx" */),
  "component---src-pages-fat-dissolving-tsx": () => import("./../../../src/pages/fat-dissolving.tsx" /* webpackChunkName: "component---src-pages-fat-dissolving-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lash-lift-and-tint-tsx": () => import("./../../../src/pages/lash-lift-and-tint.tsx" /* webpackChunkName: "component---src-pages-lash-lift-and-tint-tsx" */),
  "component---src-pages-lip-fillers-tsx": () => import("./../../../src/pages/lip-fillers.tsx" /* webpackChunkName: "component---src-pages-lip-fillers-tsx" */),
  "component---src-pages-microblading-tsx": () => import("./../../../src/pages/microblading.tsx" /* webpackChunkName: "component---src-pages-microblading-tsx" */),
  "component---src-pages-portfolio-tsx": () => import("./../../../src/pages/portfolio.tsx" /* webpackChunkName: "component---src-pages-portfolio-tsx" */),
  "component---src-pages-russian-lips-tsx": () => import("./../../../src/pages/russian-lips.tsx" /* webpackChunkName: "component---src-pages-russian-lips-tsx" */),
  "component---src-pages-tear-trough-filer-tsx": () => import("./../../../src/pages/tear-trough-filer.tsx" /* webpackChunkName: "component---src-pages-tear-trough-filer-tsx" */),
  "component---src-pages-treatments-tsx": () => import("./../../../src/pages/treatments.tsx" /* webpackChunkName: "component---src-pages-treatments-tsx" */)
}

